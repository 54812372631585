import React, { Suspense, useEffect, useRef, useState } from "react";
import bnrImage from "../../../assets/images/compress/bg-img-c.png";


// Lazy load the components
const LazyImage = React.lazy(() => import("./LazyImage")); // Assuming you have a separate LazyImage component
const LazyVideo = React.lazy(() => import("./LazyVideo")); // Assuming you have a separate LazyVideo component

const BannerComponent = ({showImage , setIsPlaying ,  setShowImage , videoRef}) => {

  
  useEffect(() => {
    if (videoRef.current) {
      console.log("Video ref is assigned", videoRef.current);
    } else {
      console.log("Video ref is still undefined");
    }
  }, [showImage]);
  
console.log("videoRef" , videoRef)
  const handleVideoEnded = () => {
    setIsPlaying(false);
    setShowImage(true);
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {showImage ? (
        <LazyImage src={bnrImage} alt="Placeholder Image" />
      ) : (
        <LazyVideo
          src="https://meadows-bucket-production.s3.eu-west-1.amazonaws.com/homeVideo/NewHomepageVideo.mp4"
          ref={videoRef}
          onEnded={handleVideoEnded}
        />
      )}
    </Suspense>
  );
};

export default BannerComponent;
