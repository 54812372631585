import React, { Suspense } from 'react';
import adviserbgImage from "../../assets/images/adviser-bg-img.png";
import msgbox from "../../assets/images/msg-box.png";
import angleicon from "../../assets/images/angle-icon.png";
import whoweare from "../../assets/images/who-we-are.png";
import planing from "../../assets/images/planing.png";
import { Link } from 'react-router-dom';
import anglerighticon from "../../assets/images/angle-right-icon.png";
const LazyImage = React.lazy(() => import("../Common/Optemize/LazyImage")); 


const Advisor = () => {
  return (
    <>
       <section id="Adviser-main">
        <div className="adviser-bg-img">
        <Suspense fallback={<div>Loading...</div>}>
                <LazyImage src={adviserbgImage} alt="overview image"/>
                </Suspense>
               
          {/* <img src={adviserbgImage} alt="background data" /> */}
        </div>
        <div className="future-planing">
          <div className="container">
            <div className="row">
              <div className="talk-adviser-col">
                <div className="talk-adviser">
                  <div className="msg-img">
                    <img src={msgbox} alt="message box" />
                  </div>
                  <div className="adviser-head">
                    <h3>Speak to an Advisor</h3>
                    <p>
                      For more information please contact us for a no obligation
                      call. Please click on SCHEDULE A CALL button
                    </p>
                  </div>
                  <div className="adviser-contact">
                    <Link to="/schedule-a-call">
                      Schedule a Call <img src={anglerighticon} alt="schedule call" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="adviser-plans-col">
                <div className="adviser-plans">
                  <div className="adviser-content">
                    <h2>The Story Behind</h2>
                    <p>
                      In 1976, when I was just eight years old, I experienced
                      the loss of my mother to cancer. Back then, the approach
                      to death...
                    </p>
                    <Link to="/story-behind">
                      Learn More <img src={angleicon} alt="angle-right-icon" />
                    </Link>
                  </div>
                  <div className="adviser-img">
                    <img src={whoweare} alt="who we are" />
                  </div>
                </div>
              </div>
              <div className="adviser-plans-col">
                <div className="adviser-plans">
                  <div className="adviser-content">
                    <h2>The Importance of Planning Ahead</h2>
                    <p>
                      Navigating the uncertain landscapes of the future might
                      seem overwhelming, but with Inspired Meadows...
                    </p>
                    <Link to="/planning-ahead">
                      Learn More <img src={angleicon} alt="angle-right-icon" />
                    </Link>
                  </div>
                  <div className="adviser-img">
                    <img src={planing} alt="planing data" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Advisor;
