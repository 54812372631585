import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";

import bannerimg from "../../assets/images/Contact_bg_img.png";
import Email_contact_icon from "../../assets/images/Email_contact_icon.png";
import Phone_contact_icon from "../../assets/images/Phone_contact_icon.png";
import Address_icon from "../../assets/images/Address_icon.png";
import Whatsup_icon from "../../assets/images/whatsUp_icon.png";
import anglerighticon from "../../assets/images/angle-right-icon.png";
import CustomInput from "../Common/CustomInput";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import agent from '../../agent';
import Loader from '../components/Loader';
import Spinner from '../components/Spinner';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => ({
    ...state,
});

const mapDispatchToProps = (dispatch) => ({
});
 
const MainView = (props) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });
    const [errors, setErrors] = useState({
        email: "",
        phone: "",
    });

    const [loading , setIsLoading] = useState(false)
    // const thanksAndRegards = 'Thanks and Regards';
    const { name, email, phone, subject, message } = formData;

    const validateEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    };

    const validatePhone = (phone) => {
        const phonePattern = /^\d{10}$/;
        return phonePattern.test(phone);
    };
    // const mailtoLink = `mailto:info@inspiredmeadows.co.uk?subject=${encodeURIComponent(
    //     subject
    // )}&body=${encodeURIComponent(
    //     `${message}\n\nName: ${name}\nEmail: ${email}\nPhone: ${phone}\n\n${thanksAndRegards}`
    // )}`;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "phone") {
            const numericValue = value.replace(/\D/g, '');
            if (numericValue.length <= 12) {
                setFormData({
                    ...formData,
                    [name]: numericValue,
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
        if (name === "email") {
            if (!validateEmail(value)) {
                setErrors({
                    ...errors,
                    email: "Invalid email format",
                });
            } else {
                setErrors({
                    ...errors,
                    email: "",
                });
            }
        }
    };


    const handleSendEmail = async(e) => {
        
        e.preventDefault();
        if (!validateEmail(formData.email)) {
           
            setErrors({
                ...errors,
                email: "Invalid email format",
            });
            return;
          }
          if (!validatePhone(formData.phone)) {
            setErrors({
              ...errors,
              phone: "Invalid phone number format",
            });
          }
          const data = {
            "email": formData?.email,
            "message": formData?.message,
            "name": formData?.name,
            "phoneNumber": {
              "dialCode": "+44",
              "number": formData?.phone,
            },
            "subject": formData?.subject
          }
        try {
            setIsLoading(true)
            const response = await agent.common.sendContactMail(data);
            toast.success(response.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            setIsLoading(false)
            setFormData({
                name: '', 
                email: '',
                phone: '',
                subject: '',
                message: ''
            });
        } catch (error) {
            setIsLoading(false)
            if (error.response && error.response.statusCode === 400) {
                const errorResponse = JSON.parse(error.response.text);
                const errorMessage = errorResponse.message;
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    };

    return (
        <Fragment>
           
            <section id="banner-img-main">
                <div className="custom-banner-img">
                    <img src={bannerimg} alt="banner-img" />
                </div>
                <div className="custom-banner-text">
                    <div className="container">
                        <h1>Contact Us</h1>
                    </div>
                </div>
            </section>
             <ToastContainer/>
            <section id="contact-main">
                <div className="container">
                    <div className="row">
                        <div className="contact-details-col">
                            <div className="contct-details">
                                <div className="contact-head">
                                    <h2>Please contact us</h2>
                                    <p>For a no obligation call if you would like more information,
                                         or just a friendly chat…... Choose your preferred method 
                                         of contact and one of our Advisors will be in touch.</p>
                                    <Link to='/schedule-a-call'>Schedule a Call <img src={anglerighticon} alt="" /></Link>
                                </div>
                                <div className="contact-social">
                                    <ul>
                                        <li><span><img src={Email_contact_icon} alt="" /></span>
                                            <div className="social-left">
                                                <label>Email:</label>
                                                <a href="mailto:info@inspiredmeadows.co.uk">info@inspiredmeadows.co.uk</a>
                                            </div>
                                        </li>
                                    </ul>
                                    
                                    <ul>
                                        <li><span><img src={Address_icon} alt="" /></span>
                                            <div className="social-left">
                                                <label>Address:</label>
                                                <a href='https://www.google.com/maps/search/101000,+Moscow,+Arkhangelsky+pereulok,+9%D1%811,+office+6/@55.7620785,37.6178569,14z/data=!3m1!4b1?entry=ttu' target="_blank">
                                                <p>Inspired Meadows Ltd</p>
                                                <p>Broadwalk House</p>
                                                <p>Southernhay West</p>
                                                <p>Exeter</p>
                                                EX1 1TS</a>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li><span><img src={Phone_contact_icon} alt="" /></span>
                                            <div className="social-left">
                                                <label>Phone:</label>
                                                <a href="tel:+441392576302">44 (0) 1392 576302</a>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li><span><img src={Whatsup_icon} alt="" /></span>
                                            <div className="social-left">
                                                <label>Whatsapp: </label>
                                                <a href="https://wa.me/447759123456" target="_blank" rel="noopener noreferrer">
                                                44 (0) 7761 064 750</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="contact-form-col">
                            <div className="contact-form">
                                <form>
                                    <h3>How can we help you?</h3>
                                    <div className="input-group">
                                        <CustomInput
                                            type="text"
                                            name="name"
                                            label="Name"
                                            value={name}
                                            required={true}
                                            handleChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <CustomInput
                                            type="email"
                                            name="email"
                                            label="Email address"
                                            value={email}
                                            required={true}
                                            handleChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <CustomInput
                                            type="text"
                                            name="phone"
                                            label="Phone number"
                                            value={phone}
                                            required={true}
                                            handleChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <CustomInput
                                            type="text"
                                            name="subject"
                                            label="Subject"
                                            value={subject}
                                            required={true}
                                            handleChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input-group" >
                                        <CustomInput
                                            type="text"
                                            name="message"
                                            label="Message"
                                            value={message}
                                            required={true}
                                            handleChange={handleInputChange}
                                            
                                        ></CustomInput>
                                    </div>
                                    <div className="input-group">
                                        <button onClick={handleSendEmail}  disabled={loading}> {loading ? <Spinner/> : "Send Message"}</button>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MainView);