import React from 'react';
import { purchaseData } from "../Common/common";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Purchase = () => {

    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              arrows: false,
              dots: true,
              slidesToShow: 2,
            },
          },
    
          {
            breakpoint: 575,
            settings: {
              arrows: false,
              dots: true,
              slidesToShow: 1,
            },
          },
        ],
      };
  return (
    <>
     <section id="purchase-main">
        <div className="container">
          <div className="row">
            <div className="purchase-head-col">
              <div className="purchase-head">
                <h2>Purchase Now</h2>
              </div>
            </div>
          </div>
          <div className="purchase-product">
            {purchaseData?.map((item, index) =>
              index === 0 ? (
                <div
                  key={item.id}
                  className={item.id === 4 ? "urn-text" : "purchase-col"}
                >
                  <div className="purchase-content">
                    <img src={item?.image} alt="purchase imageNo" />
                    <div className="purchase-links">
                      <span>{item?.title}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  key={item.id}
                  className={item.id === 4 ? "urn-text" : "purchase-col"}
                >
                  <Link to={`/products/${index == 1 ? "urn-plots": index == 2 ? "flowers" : index == 3 ? "trees" : ""}`}>
                    <div className="purchase-content">
                      <img src={item?.image} alt="purchase imageOne" />
                      <div className="purchase-links">
                        <span>{item?.title}</span>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            )}
          </div>
          <div class="purchase-for-mobile">
            <div class="row">
              <div class="purchase-slider">
                <div className="slide">
                  <Slider {...settings}>
                    {purchaseData?.map((data , index) => (
                      <div key={index}>
                        <div class="purchase-col">
                          <div class="purchase-content">
                            <img src={data?.image} alt="purchase imageTwo" />
                            <div class="purchase-links">
                              <span>{data?.title}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default Purchase;
