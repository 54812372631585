import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import agent from "../../agent";

import alert from "../../assets/images/alert_icon.png";
import { setCartData } from "../../reducers/slices/headerSlice";
import InlineLoader from "../components/InlineLoader";
import AdditionalItem from "./AdditionalItem";
import Buyers from "./Buyers";
import CheckoutModal from "./CheckoutModal";
import EmptyView from "./EmptyView";
import OrderCompleteModal from "./OrderComplete";
import PlotItem from "./PlotItem";
import { calculateTotalMaintenancePrice } from "../../utils";

const mapStateToProps = (state) => ({
  userId: state.common?.currentUser?.id ?? "",
  cartDataLength: state.header.cartData,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
  setCartData: (count) => dispatch(setCartData(count)),
});

const initialCheckoutData = {
  customerId: "",
  ephemeralKey: "",
  paymentIntent: "",
  publishableKey: "",
  orderNumber: "",
};

const MainView = (props) => {
  const { userId } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [tax, setTax] = useState(0);
  const [maintenanceFees, setMaintenanceFees] = useState(0);
  const [additionalTax, setAdditionalTax] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const [isPlotConfirmationOpen, setIsPlotConfirmationOpen] = useState(false);
  const [isAddCardOpen, setIsAddCardOpen] = useState(false);
  const [isOrderCompleteOpen, setIsOrderCompleteOpen] = useState(false);
  const [checkoutData, setCheckoutData] = useState(initialCheckoutData);
  const [openAddBuyers, setOpenAddBuyers] = useState(false);
  const [hasPlotInOrder, setHasPlotInOrder] = useState(true);
  const navigate = useNavigate();
  const dispatch = useNavigate();

  const [stripeValue, setStripeValue] = useState();


  const currentUser = useSelector((state) => state.common.currentUser);

  const stripePromise = loadStripe(process.env.REACT_APP_MEADOWS_STRIPE_KEY);
  const stripeTestPromise = loadStripe(process.env.REACT_APP_MEADOWS_STRIPE_TEST_KEY);

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.email === "kay@inspired.us") {
        setStripeValue(stripeTestPromise);
      } else {
        setStripeValue(stripePromise);
      }
    }
  }, [currentUser]);

  const fetchCartProducts = async () => {
    try {
      if (!userId) throw new Error("Login first");
      setIsLoading(true);
      setError("");
      const res = await agent.common.getCart(userId);
      const { status, message } = res;
      if (!status) throw new Error(message);
      setData(res?.data ?? []);
      setTax(res?.taxPercentage ?? 0);
      setMaintenanceFees(res?.maintenanceFees);
      props.setCartData(res?.data?.length);
    } catch (err) {
      console.error(err);
      if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCartProducts();
  }, [userId]);

  let additionalItemTax = 0;

  const { subTotalPrice, subTotalItems } = data.reduce(
    (acc, cur) => {
      if (
        cur.productType === "Burial Plots" ||
        cur.productType === "Urn Plots"
      ) {
        acc.subTotalPrice += cur?.plotData?.totalPrice * cur.quantity;
      } else {
        const additionalItemData = cur.additionalItemData.price * cur.quantity;
        additionalItemTax += additionalItemData;
        acc.subTotalPrice += additionalItemData; // Add the tax to subTotalPrice
      }
      acc.subTotalItems += cur.quantity;
      return acc;
    },
    { subTotalPrice: 0, subTotalItems: 0, additionalItemTax: 0 }
  );

  const hasAdditionalItems = additionalItemTax > 0;
  const updateProductQuantity = async (cartItemId, action, setIsLoading) => {
    try {
      setIsLoading(true);

      const res = await agent.common.updateQuantity(cartItemId, action);
      const { status, message } = res;
      if (!status) throw new Error(message);
      setData((prevVal) => {
        const newVal = [...prevVal];
        const idx = newVal.findIndex((el) => el.id === cartItemId);
        if (idx !== -1) {
          if (action === "increment") {
            newVal[idx].quantity += 1;
          } else {
            newVal[idx].quantity -= 1;
            if (newVal[idx].quantity === 0) {
              newVal.splice(idx, 1);
            }
          }
        }

        return newVal;
      });
    } catch (err) {
      if (err instanceof Error) {
        
      } else {
       
      }
    } finally {
      setIsLoading(false);
    }
  };
  const removeCartProduct = async (cartItemId) => {
    try {
      const res = await agent.common.deleteCartItem(cartItemId);
      const { status, message } = res;
      if (!status) throw new Error(message);

      fetchCartProducts();
    } catch (err) {
      if (err instanceof Error) {
      
      } else {
        console.error(err);
      }
    }
  };

  const handleCheckout = async (formData, buyerFormData) => {
    setIsLoading(true);
    setOpenAddBuyers(false);
    const orderData = data.map((item) => {
      return {
        cartId: item.id,
        productId: item.productId,
        productType: item.productType,
        quantity: item.quantity,
      };
    });
    const buyerData = [
      {
        // "address": formData?.address,
        contactNumber: {
          dialCode: "+44",
          number: formData?.contact,
        },
        email: formData?.email,
        firstName: formData?.name,
        lastName: formData?.lastName,

        postalCode: formData?.postCode,
        type: "Buyer 1",
        address:
          formData?.town_or_city +
          " " +
          formData.houseName +
          " " +
          formData.street +
          " " +
          formData?.postCode +
          " " +
          formData.county +
          " " +
          formData.country,
        country: formData.country,
        houseName: formData.houseName,
        street: formData.street,
        county: formData.county,
        townOrCity: formData?.town_or_city,
      },
    ];

    const buyerDataNew = [
      {
        // "address": formData?.address,
        contactNumber: {
          dialCode: "+44",
          number: formData?.contact,
        },
        email: formData?.email,
        firstName: formData?.name,
        lastName: formData?.lastName,
        postalCode: formData?.postCode,
        type: "Buyer 1",
        address:
          formData?.town_or_city +
          " " +
          formData.houseName +
          " " +
          formData.street +
          " " +
          formData?.postCode +
          " " +
          formData.county +
          " " +
          formData.country,
        country: formData.country,
        houseName: formData.houseName,
        street: formData.street,
        county: formData.county,
        townOrCity: formData?.town_or_city,
      },
      {
        // "address": buyerFormData?.address,
        contactNumber: {
          dialCode: "+44",
          number: buyerFormData?.contact,
        },
        email: buyerFormData?.email,
        firstName: buyerFormData?.name,
        lastName: buyerFormData?.lastName,
        postalCode: buyerFormData?.postCode,
        type: "Buyer 2",
        address:
          buyerFormData?.town_or_city +
          " " +
          buyerFormData.houseName +
          " " +
          buyerFormData.street +
          " " +
          buyerFormData?.postCode +
          " " +
          buyerFormData.county +
          " " +
          buyerFormData.country,
        country: buyerFormData.country,
        houseName: buyerFormData.houseName,
        street: buyerFormData.street,
        county: buyerFormData.county,
        townOrCity: buyerFormData?.town_or_city,
      },
    ];
    const buyerDataToSend = buyerFormData?.email ? buyerDataNew : buyerData;
    toast.promise(
      agent.common.addOrder({
        buyerAddress: buyerDataToSend,
        items: orderData,
        userId: userId,
      }),
      {
        loading: "Creating order...",
        success: (res) => {
          const { status, message, itemIsNotAvailable } = res;
          if (!status) throw new Error(message);
          const {
            ephemeralKey,
            paymentIntent,
            publishableKey,
            customerId,
            orderNumber,
          } = res;
          setCheckoutData({
            ephemeralKey,
            paymentIntent,
            publishableKey,
            customerId,
            orderNumber,
          });

          setIsCheckoutOpen(true);
          setIsLoading(false);
          return "Order created successfully!";
        },
        error: (err) => {
        

          if (err instanceof Error) {
            try {
              const errorMessage = JSON.parse(err.message);
              if (errorMessage.itemIsNotAvailable) {
                fetchCartProducts()
                  .then((cartRes) => {
                    // window.location.reload();
                  })
                  .catch((cartErr) => {
                    console.error(cartErr);
                  });
              } else {
                setIsPlotConfirmationOpen(true);
              }
            } catch (parseError) {
              console.error(parseError);
             
              setIsPlotConfirmationOpen(true);
            }
          } else {
           
            setIsPlotConfirmationOpen(true);
          }
          return `Error: ${error.message}`;
        },
      }
    );
  };

  const handleAdditionalCheckout = async () => {
    const orderData = data.map((item) => {
      return {
        cartId: item.id,
        productId: item.productId,
        productType: item.productType,
        quantity: item.quantity,
      };
    });

    toast.promise(
      agent.common.addOrder({
        items: orderData,
        userId: userId,
      }),
      {
        loading: "Creating order...",
        success: (res) => {
          const { status, message, itemIsNotAvailable } = res;
          if (!status) throw new Error(message);
          const {
            ephemeralKey,
            paymentIntent,
            publishableKey,
            customerId,
            orderNumber,
          } = res;
          setCheckoutData({
            ephemeralKey,
            paymentIntent,
            publishableKey,
            customerId,
            orderNumber,
          });

          setIsCheckoutOpen(true);
          setIsLoading(false);
          return "Order created successfully!";
        },
        error: (err) => {
       

          if (err instanceof Error) {
            try {
              const errorMessage = JSON.parse(err.message);
              if (errorMessage.itemIsNotAvailable) {
                fetchCartProducts()
                  .then((cartRes) => {
                    // window.location.reload();
                  })
                  .catch((cartErr) => {
                    console.error(cartErr);
                  });
              } else {
                setIsPlotConfirmationOpen(true);
              }
            } catch (parseError) {
              console.error(parseError);
              
              setIsPlotConfirmationOpen(true);
            }
          } else {
           
            setIsPlotConfirmationOpen(true);
          }
          return `Error: ${error.message}`;
        },
      }
    );
  };

  const handleOpenBuyersDetail = (value) => {
    setOpenAddBuyers(value);
  };

  const handleCheckPlotDetail = () => {
    if (data && data.some((isPlot) => isPlot.plotData)) {
      handleOpenBuyersDetail(true);
    } else {
      handleAdditionalCheckout();
    }
  };

  if (isLoading)
    return (
      <div
        style={{
          height: 400,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <InlineLoader />
      </div>
    );

  if (!isLoading && error)
    return (
      <div
        style={{
          height: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "crimson",
          fontWeight: 600,
          fontSize: 19,
        }}
      >
        <div>{error}</div>
      </div>
    );

  const cartData = localStorage.setItem("cartData", data?.length);

  return (
    <Fragment>
      {/* <Toaster
        reverseOrder={false}
        toastOptions={{ position: 'bottom-center' }}
      /> */}
      {isBlocked && (
        <div className="error-message">
          <p>You are blocked by admin.</p>
        </div>
      )}
      {data && data.length ? (
        <section id="cart-main">
          <div className="container">
            <div className="row">
              <div className="cart-head-col">
                <div className="cart-head">
                  <h1>Cart ({data.length})</h1>
                </div>
              </div>

              <div className="cart-item-col">
                <div className="cart-item-main">
                  <div className="cart-itme-head">
                    <span>Products</span>
                  </div>

                  {data &&
                    data.map((item) =>
                      item.productType === "Burial Plots" ||
                      item.productType === "Urn Plots" ? (
                        <PlotItem
                          key={item.id}
                          item={item}
                          removeCartProduct={removeCartProduct}
                        />
                      ) : (
                        <AdditionalItem
                          key={item.id}
                          item={item}
                          removeCartProduct={removeCartProduct}
                          updateProductQuantity={updateProductQuantity}
                        />
                      )
                    )}
                </div>

                <div className="add-cart-item">
                  <button
                    className="add-item"
                    onClick={() => navigate(`/products/flowers`)}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    Add Additional Items
                  </button>
                </div>
              </div>

              <div className="cart-checkout-col">
                <div className="cart-checkout">
                  <div className="checkout-head">
                    <span>Summary</span>
                  </div>
                  <div className="checkout-field">
                    <label>Subtotal items ({subTotalItems})</label>
                    <span>£ {subTotalPrice.toFixed(2)}</span>
                  </div>
                  <div className="checkout-field">
                    <label>One-time Maintenance Charge </label>
                    <span>
                      £ {calculateTotalMaintenancePrice(data, maintenanceFees)}
                    </span>
                  </div>
                  {hasAdditionalItems ? (
                    <div className="checkout-field">
                      <label>Vat</label>
                      <span>
                        £ {((additionalItemTax * tax) / 100).toFixed(2)}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="checkout-total">
                    <label>Total</label>
                    <span>
                      £{" "}
                      {(
                        calculateTotalMaintenancePrice(data, maintenanceFees) +
                        subTotalPrice +
                        (hasAdditionalItems && tax !== 0
                          ? (additionalItemTax * tax) / 100
                          : 0)
                      ).toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="Checkout-btn">
                  <button onClick={handleCheckPlotDetail}>
                    Proceed to Checkout
                  </button>
                </div>
                {openAddBuyers && (
                  <Buyers
                    handleCheckout={handleCheckout}
                    handleOpenBuyersDetail={handleOpenBuyersDetail}
                  />
                )}
                {isPlotConfirmationOpen && (
                  <>
                    <div
                      style={{ position: "fixed" }}
                      className={`overlay ${
                        isPlotConfirmationOpen ? "alert-active" : ""
                      }`}
                    />
                    <div className="plot-confirmation">
                      <img src={alert} alt="alert icon" />
                      <span>This selection is invalid.</span>
                      <p>
                        Please select and add Plot(s) to cart before adding
                        Additional Items.
                      </p>
                      <button
                        onClick={(e) => {
                          setIsPlotConfirmationOpen(false);
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <EmptyView />
      )}

      <OrderCompleteModal
        closeModal={() => {
          setIsOrderCompleteOpen(false);
          fetchCartProducts();
        }}
        isModalOpen={isOrderCompleteOpen}
        hasPlotInOrder={hasPlotInOrder}
      />
      {checkoutData.paymentIntent && (
        <Elements
          stripe={stripeValue}
          options={{
            clientSecret: checkoutData.paymentIntent,
          }}
        >
          <CheckoutModal
            closeModal={() => {
              setIsCheckoutOpen(false);
              setCheckoutData(initialCheckoutData);
            }}
            isModalOpen={isCheckoutOpen}
            clientSecret={checkoutData.paymentIntent}
            openSuccessModal={() => setIsOrderCompleteOpen(true)}
            orderNumber={checkoutData.orderNumber}
            setHasPlotInOrder={setHasPlotInOrder}
          />
        </Elements>
      )}
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
