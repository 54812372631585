import React from 'react';
import bannerimg from "../../assets/images/banner-img.3.png";


const AboutHeader = () => {
  return (
    <>
      <section id="banner-img-main">
        <div className="custom-banner-img">
          <img src={bannerimg} alt="banner-img" />
        </div>
        <div className="custom-banner-text">
          <div className="container">
            <h1>About Inspired Meadows</h1>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default AboutHeader;
