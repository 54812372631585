import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import client_four from "../../assets/images/compress/advisor4.jpeg";
import client_two from "../../assets/images/compress/advisor2.jpg";
import client_first from "../../assets/images/compress/advisor1.jpg";
import client_three from "../../assets/images/compress/advisor3.jpg";
import anglerighticon from "../../assets/images/angle-right-icon.png";


const LazyImage = React.lazy(() => import("../Common/Optemize/LazyImage")); 

const TeamSection = () => {
  // Array of image objects
  const images = [
    { alt: 'Client_img_one', src: client_first },
    { alt: 'Client_img_two', src: client_four },
    { alt: 'Client_img_data', src: client_two },
    { alt: 'Client_img_data_one', src: client_three },
  ];

  return (
    <section id="team-main">
      <div className="container">
        <div className="row">
          <div className="team-content-col">
            <div className="team-content">
              <h2>Our Friendly Advisors</h2>
              <p>
                Our friendly advisors are on hand to guide and advise you.
                Please feel free to contact us for a no-obligation call.
                Please click below to schedule a call.
              </p>
              <Link to="/schedule-a-call">
                Schedule a Call <img src={anglerighticon} alt="angle_icon" />
              </Link>
            </div>
          </div>
          <div className="team-profile-col">
            <div className="team-profile">
              <ul>
                {images.map((image, index) => (
                  <li key={index}>
                      <Suspense fallback={<div>Loading...</div>}>
                <LazyImage src={image.src} alt="image.alt"/>
                </Suspense>
                    {/* <img alt={image.alt} src={image.src} /> */}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
