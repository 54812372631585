import React, { useEffect, useState,useRef } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import logo from "../../assets/images/logo.svg";
import video_icon from "../../assets/images/schedule_icon.svg";
import { TOP_HEADER } from "./../../constants/header.js";
import Login from "../Auth/Login.js";
import Register from "../Auth/Register.js";
import ForgotPassword from "../Auth/ForgotPassword.js";
import VerifyOtp from "../Auth/VerifyOtp.js";
import agent from '../../agent';
import ResetPassword from "../Auth/ResetPassword.js";
import ResetComplete from "../Auth/ResetComplete.js";
import ProfileViewer from "../User/ProfileViewer.js";
import { LOGOUT } from "../../constants/actionTypes";
import {
    setEmail,
    setPassword,
    setCartData,
    setShowLogin,
    setShowRegister,
    setShowForgot,
    setShowLogout,
    setVerifyOtp,
    setResetPass,
    setResetComplete,
    setForgotEmail,
} from "../../reducers/slices/headerSlice";
import Brochure from "../../assets/IM_Brochure_Final_website.pdf"

const mapStateToProps = (state) => ({
    ...state,
    currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
    onSignOut: () => {
        dispatch({ type: LOGOUT });
    },
});

const Header = (props) => {
    const { currentUser, onSignOut } = props;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [showProfileDrop, setShowProfileDrop] = useState(false);
    const [profilePic, setProfilePic] = useState("");
    const [showPassowrd, setShowPassword] = useState(false);
    const [loginErr, setLoginErr] = useState(null);
    const [cartCount, setCartCount] = useState(0);
    const [sliderState, setSliderState] = useState(false);
    const dropdownRef = useRef(null); 
    const userId = currentUser?.id;
    const {
        data,
        rememberMe,
        forgotEmail,
        showLogin,
        showRegister,
        showForgot,
        showVerifyOtp,
        showResetPass,
        showResetComplete,
        showLogout,
    } = useSelector((state) => state.header);

    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        if (currentUser ) {
            setProfilePic(currentUser.image);
        }
    }, [currentUser]);

   
    useEffect(() => {
        const handleClickOutsideDropdown = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowProfileDrop(false);
            }
        };
    
        document.addEventListener("click", handleClickOutsideDropdown);
    
        return () => {
            document.removeEventListener("click", handleClickOutsideDropdown);
        };
    }, [currentUser, dropdownRef]);


    const handleCloseLogin = () => {
        dispatch(setShowLogin(false));
        setEmail("");
        setPassword("");
    };
    const handleClickSlider = () =>{
        setSliderState(true);
        document.body.classList.add('menu-active');
    }
    const handleCloseSlider = () =>{
        setSliderState(false);
        document.body.classList.remove('menu-active');
    }

    
    const handleShowLogin = () => {
        const storedEmail = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        setEmail(storedEmail || '');
        setPassword(storedPassword || '');
        dispatch(setShowLogin(true));
        dispatch(setShowRegister(false));
        dispatch(setResetPass(false));
        dispatch(setShowForgot(false));
        dispatch(setVerifyOtp(false));
        dispatch(setResetPass(false));
        dispatch(setResetComplete(false));

    };

    const handleCloseRegister = () => dispatch(setShowRegister(false));
    const handleShowRegister = () => {
        
        dispatch(setShowLogin(false));
        dispatch(setShowRegister(true));
        dispatch(setResetPass(false));
        dispatch(setShowForgot(false));
        dispatch(setVerifyOtp(false));
        dispatch(setResetPass(false));
        dispatch(setResetComplete(false));
    };

    const handleCloseForgot = () => dispatch(setShowForgot(false));
    const handleShowForgot = () => {
       
        dispatch(setShowLogin(false));
        dispatch(setShowRegister(false));
        dispatch(setResetPass(false));
        dispatch(setShowForgot(true));
        dispatch(setVerifyOtp(false));
        dispatch(setResetComplete(false));
    };

    const handleCloseVerify = () => dispatch(setVerifyOtp(false));
    const handleShowVerify = () => {
       
        dispatch(setShowLogin(false));
        dispatch(setShowRegister(false));
        dispatch(setResetPass(false));
        dispatch(setShowForgot(false));
        dispatch(setVerifyOtp(true));
        dispatch(setResetPass(false));
        dispatch(setResetComplete(false));
    };
    const handleCloseResetPass = () => dispatch(setResetPass(false));
    const handleShowResetPass = () => {
       
        dispatch(setShowLogin(false));
        dispatch(setShowRegister(false));
        dispatch(setResetPass(false));
        dispatch(setShowForgot(false));
        dispatch(setVerifyOtp(false));
        dispatch(setResetPass(true));
        dispatch(setResetComplete(false));
    };
    const handleCloseResetPassComplete = () => dispatch(setResetComplete(false));
    const handleShowResetPassComplete = () => {
       
        dispatch(setShowLogin(false));
        dispatch(setShowRegister(false));
        dispatch(setResetPass(false));
        dispatch(setShowForgot(false));
        dispatch(setVerifyOtp(false));
        dispatch(setResetPass(false));
        dispatch(setResetComplete(true));
    };
    const handleShowProfileDropper = () => {
        setShowProfileDrop(!showProfileDrop);
    };

    const submitLogout = () => dispatch(setShowLogout(true));
    const cancelConfirm = () => dispatch(setShowLogout(false));
    const confirmLogout = () => {
        dispatch(setShowLogout(false));
        setShowProfileDrop(false);
        onSignOut();
       
    };

    const handleShowMyProfile = () => {
        dispatch(setShowLogout(false));
        setShowProfileDrop(false);
        navigate("/user/my-profile");
    };
    let loginProps = {
        showLogin,
        handleCloseLogin,
        handleShowLogin,
        handleShowRegister,
        handleShowForgot,
        email,
        setEmail,
        password,
        setPassword,
        showPassowrd,
        setShowPassword,
        loginErr,
        setLoginErr,
    };

    let registerProps = {
        showRegister,
        handleCloseRegister,
        handleShowLogin,
        handleShowVerify,
    };

    let forgotProps = {
        showForgot,
        handleCloseForgot,
        handleShowLogin,
        setForgotEmail,
        handleShowVerify,
    };

    let verifyProps = {
        handleCloseVerify,
        handleShowLogin,
        forgotEmail,
        showVerifyOtp,
        handleShowResetPass,

    };
    let resetPassProps = {
        showResetPass,
        handleShowLogin,
        forgotEmail,
        handleCloseResetPass,
        handleShowResetPassComplete,
    };

    let resetCompleteProps = {
        handleShowLogin,
        handleCloseResetPassComplete,
        showResetComplete,
    };
    let profileProps = {
        currentUser,
        submitLogout,
        showProfileDrop,
        handleShowMyProfile,
    };


    useEffect(() => {
        const fetchCartProducts = async () => {
          try {
            if (!userId) throw new Error('Login first');
            setIsLoading(true);
            setError('');
            const res = await agent.common.getCart(userId);
            const { status, message } = res;
            if (!status) throw new Error(message);
            dispatch(setCartData(res?.data?.length));
          } catch (err) {
            console.error(err);
            if (err instanceof Error) {
              setError(err.message);
            }
          } finally {
            setIsLoading(false);
          }
        };

        const cartCountFromLocalStorage = localStorage.getItem('cartData');
        if (currentUser && currentUser.email) {
          fetchCartProducts();
        } else {
          dispatch(setCartData(cartCountFromLocalStorage));
        }
      }, [currentUser]);
      

    const generateInitials = (name) => {
        const nameParts = name.split(" ");
        if (nameParts.length === 1) {
          return nameParts[0].charAt(0).toUpperCase();
        } else if (nameParts.length > 1) {
          return (
            nameParts[0].charAt(0).toUpperCase() + nameParts[nameParts.length - 1].charAt(0).toUpperCase()
          );
        }
        return "";
      };


      const onBrochureButtonClick = () => {
        const pdfUrl = Brochure;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.setAttribute("download", "Brochure.pdf"); 
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="header-main">
            <header id="header-main">
                <div className="header-logo">
                    <Link to="/">
                        <img src={logo} alt="logo" />
                    </Link>
                </div>
                <nav aria-label="breadcrumb">
                <div style={{ position: 'fixed' }}
                className={`overlay ${sliderState ? 'menu-active' : ''}`}/>
                <ul className={`true ${sliderState ? 'menu-active' : ''}`}>
                    <span><i className="fa fa-times" aria-hidden="true" onClick={handleCloseSlider}></i></span>
                        {TOP_HEADER.map((item) => (
                            <li key={item.id}>
                                <NavLink
                                to={item.link} 
                                activeclassname="active"
                                onClick={handleCloseSlider}
                                >
                                {item.name}
                                </NavLink>
                            </li>
                        ))}
                        <li  style={{cursor:"pointer"}}>
                        <NavLink to="/brochure" >  Brochure</NavLink>
                           
                        </li>
                        <li>
                            <span className="active for-mobile-show" href="/schedule-a-call"> <img src={video_icon}
                                alt=""/> Schedule a
                        Call</span></li>

                    </ul>
                </nav>
                <div className="contect-details">
                    <ul>
                        <li>
                            <Link className="bar-btn"><i className="fa fa-bars" aria-hidden="true" onClick={handleClickSlider}></i>
                            </Link>

                        </li>
                        <li>
                            <Link className="active for-mobile-hide" to="/schedule-a-call">
                                {" "}
                                <img src={video_icon} alt="" /> Schedule a Call
                            </Link>
                        </li>
                        <li className="cart">
                            {currentUser && currentUser?.email ? (
                                <Link to="/cart">
                                    <span className="notification-number">{data}</span>
                                    <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                                </Link>
                            ) : (
                                <span className="user-link" onClick={handleShowLogin}>
                                    <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                                </span>
                            )}
                        </li>
                        <li className="profile-icon" ref={dropdownRef}>
                            <>
                                {currentUser && currentUser?.email ? (
                                    <span  className="user-link" onClick={handleShowProfileDropper}>
                                    {profilePic ? (
                                        <img src={profilePic} alt="profile image" className="profile-initials" />
                                    ) : (
                                        <div className="profile-initials">{generateInitials(currentUser?.firstName)}</div>
                                    )}
                                    </span>
                                ) : (
                                    <span className="user-link" onClick={handleShowLogin}>
                                        <i className="fa fa-user" aria-hidden="true"></i>
                                    </span>
                                )}
                            </>
                        </li>
                    </ul>
                </div>
            </header>
            <Login {...loginProps} />
            <Register {...registerProps} />
            <ForgotPassword {...forgotProps} />
            <VerifyOtp {...verifyProps} />
            <ResetPassword {...resetPassProps} />
            <ResetComplete {...resetCompleteProps} />
            <ProfileViewer {...profileProps} />
            <div
                style={{ position: 'fixed' }}
                className={`overlay ${showLogout ? 'logout-active' : ''}`}
            />
            <div className={`confirm-logout ${showLogout ? "logout-active" : ""}`}>
                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <span>Please confirm!</span>
                <p>Are you sure, you want to logout?</p>
                <ul>
                    <li>
                        <Link to="#" onClick={cancelConfirm} className="no-btn">
                            NO
                        </Link>
                    </li>
                    <li>
                        <Link to="#" onClick={confirmLogout} className="active yes-btn">
                            Yes
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
